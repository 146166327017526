import React from 'react';
import * as Avatars from "../Assets/Avatars";

const QuestionContainer = ({ message, isRequired, avatar = Avatars.point_right_small }) => {
  return (
    <div className="flex flex-col justify-center items-center gap-4">
      {avatar && <img className="w-36" src={avatar} alt='avatar' />}
      <div className='text-center'>
        <p className="text-lg text-center whitespace-normal text-gray-500 font-bold">
          {message}
        </p>
        {!isRequired && <p className="text-xs">(Optional)</p>}
      </div>
    </div>
  );
};

export default QuestionContainer;
