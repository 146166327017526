import React, { useEffect, useState } from "react";
import ChatMessage from "../QuestionContainer";
import * as Components from "../../Components";

const InsuranceType = ({ value="", required = true, isValid, onValueChange,  formData}) => {
    const [error, setError] = useState(null);
    const [_value, setName] = useState(value);

    const validate = (inputValue) => {
        inputValue = inputValue?.trim()
        if (!inputValue && required) {
            return "Please select insurance type!";
        }
    }

    const onChange = (_value) => {
        setName(_value);
        const errorMsg = validate(_value);
        if (errorMsg) {
            setError(errorMsg);
            isValid(false);
            return;
        }
        setError(null);
        isValid(true);
        onValueChange(_value);
    }

    useEffect(() => {
        const errorMsg = validate(value);
        setName(value);
        setError(errorMsg);
        errorMsg ? isValid(false) : isValid(true)
    }, []);

    return (
        <div className="w-[350px] md:w-[800px] flex flex-col items-center justify-center">
            <ChatMessage message="Select insurance type?" isRequired={required} />
            <Components.InsuranceTypes selectedInsurance={_value} onSelect={onChange}/>
            {error && <p className="text-sm text-red-500 mt-2">{error}</p>}
        </div>
    )
}
export default InsuranceType;