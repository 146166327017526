import health_insurance from "./Assets/Images/health_insurance.webp";
import life_insurance from "./Assets/Images/life_insurance.webp";
import general_insurance from "./Assets/Images/general_insurance.webp";
import purchase_policy from "./Assets/Images/new_insurance.webp";
import renew_policy from "./Assets/Images/renew_policy.webp";
import other_services from "./Assets/Images/other_services.webp";
import * as routes from "./routes";

export const FIELDS_TO_IGNORE_FROM_CLEANUP = ['service_type', 'insurance_type']

export const INSURANCE_TYPES_ENUM = {
    HEALTH_INSURANCE: "health_insurance",
    LIFE_INSURANCE: "life_insurance",
    VEHICLE_INSURANCE: "car_insurance",
    BUSINESS_INSURANCE: "business_insurance",
    GENERAL_INSURANCE: "general_insurance"
}

export const MAIN_SERVICES_ENUM = {
    POLICY_PURCHASE: "policy_purchase",
    POLICY_RENEW: "policy_renew",
    OTHER_SERVICES: "other_services",
}

export const MAIN_SERVICES = [
    {
        title: "Purchase New Policy",
        admin_title: "New Policy",
        short_admin_title: "New",
        key: MAIN_SERVICES_ENUM.POLICY_PURCHASE,
        image: purchase_policy,
        image_size: 50,
        link_to: routes.PurchasePolicy,
    },
    {
        title: "Service Existing Policy",
        admin_title: "Service Policy",
        short_admin_title: "Renewals",
        key: MAIN_SERVICES_ENUM.POLICY_RENEW,
        image: renew_policy,
        image_size: 50,
        link_to: routes.RenewPolicy,
    },
    {
        title: "Other Services",
        admin_title: "Other",
        short_admin_title: "Other",
        key: MAIN_SERVICES_ENUM.OTHER_SERVICES,
        image: other_services,
        image_size: 60,
        link_to: routes.OtherServices,
    },
]

export const INSURANCE_DETAILS = [
    {
        title: "Health Insurance",
        key: INSURANCE_TYPES_ENUM.HEALTH_INSURANCE,
        short_name: "Health",
        description: "Financial security for medical expenses, providing coverage for healthcare services and treatments.",
        image: health_insurance,
    },
    {
        title: "Life Insurance",
        key: INSURANCE_TYPES_ENUM.LIFE_INSURANCE,
        short_name: "Life",
        description: "Assurance for your loved ones' financial well-being in the event of your death, offering a payout or income replacement.",
        image: life_insurance,
    },
    {
        title: "General Insurance",
        key: INSURANCE_TYPES_ENUM.GENERAL_INSURANCE,
        short_name: "General",
        description: "General insurance provides coverage for a wide range of risks and liabilities, including business, vehicle protection against various unforeseen events.",
        image: general_insurance,
    }
]

export const BUSINESS_INSURANCE_TYPES = [
    "General Liability Insurance",
    "Property Insurance",
    "Professional Liability Insurance",
    "Workers' Compensation Insurance",
    "Business Interruption Insurance",
    "Commercial Auto Insurance",
    "Cyber Liability Insurance",
    "Directors and Officers (D&O) Insurance",
    "Product Liability Insurance",
    "Employment Practices Liability Insurance (EPLI)",
]

export const OTHER_SERVICES = [
    "Finding lost shares",
    "Demat of share",
    "Mutual fund portfolio",
    "Investment in other financial products",
]

export const SERVICE_EXISTING_POLICY_OPTIONS = [
    "Generation & maintenance of life insurance portfolio",
    "Revival of lapsed polices",
    "Online registration of all policies",
    "Fresh/ change of nominee and successive nomination",
    "Assignment/Re-assignment of policies",
    "Assistance to pay premium",
    "Registration of Bank/ Mobile / Email details",
    "Assistance in case death claim settlement",
    "Assistance in money back & maturity payment",
    "Assistance in loan on LIC policies",
    "Change of address in policies",
    "Duplicate policy, if lost",
]

export const RISK_COVER_OPTIONS = [
    "1 to 3 Lakhs",
    "5 to 6 Lakhs",
    "7 to 10 Lakhs",
    "More than 10 Lakhs",
]

export const GENERAL_INSURANCE_TYPES = [
    "Business",
    "Vehicle",
    "Other"
]

export const USER_GROUPS ={
    ADMIN: 'Admin',
    EMPLOYEE: 'Employee',
    USER: 'User'
}

// Firebase collections
export const FC_INSURANCE_LEADS = "insuranceLeads";
export const FC_USERS = "users";
