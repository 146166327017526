import React from "react";
import { INSURANCE_DETAILS } from "../constants";

const InsuranceTypes = ({ selectedInsurance, onSelect }) => {
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 justify-center">
            {INSURANCE_DETAILS.map(_type => {
                return (
                    <div className="card cursor-pointer" key={_type.key} onClick={() => onSelect(_type.key)}>
                        <div className="bg-white opacity-70">
                            <div className="bg-gradient-to-b p-4 rounded-lg shadow-md flex items-center align-middle justify-between gap-3">
                                <div className="flex items-center align-middle gap-3 w-">
                                    <img className="w-12" src={_type.image} title={_type.title} />
                                    <h3 className="text-lg font-bold">{_type.title}</h3>
                                </div>
                                <input
                                    type="radio"
                                    name="select-insurance"
                                    checked={selectedInsurance === _type.key}
                                    onChange={() => { }}
                                    className="w-6 h-6 text-blue-600 border-gray-300 focus:ring-blue-500" />
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    )
}

export default InsuranceTypes;