import React from "react";

const HeadingHero = ({ part1Title, part2Title, description, avatar, colorReverse = false }) => {
    const coloredClasses = "bg-gradient-to-tl from-blue-600 to-violet-600 text-transparent";
    const normalColorClass = "text-gray-800";

    return (
        <div className="flex flex-col align-middle justify-center">
            {
                avatar &&
                <div className="flex align-middle justify-center">
                    <img className="w-48" src={avatar} />
                </div>
            }
            <div>
                <div className="mt-5 max-w-4xl text-center mx-auto">
                    <h1 className="block font-bold text-4xl md:text-5xl lg:text-6xl">
                        <span className={`bg-clip-text ${colorReverse ? coloredClasses : normalColorClass}`}>
                            {part1Title}
                        </span>
                        <span className={`bg-clip-text  ${!colorReverse ? coloredClasses : normalColorClass}`}>
                            &nbsp;{part2Title}
                        </span>
                    </h1>
                </div>
                <div className="mt-5 max-w-4xl text-center mx-auto">
                    <p className="text-lg text-gray-600">
                        {description}
                    </p>
                </div>
            </div>
        </div>
    )
}
export default HeadingHero;