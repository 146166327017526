import React, { useEffect, useState } from "react";
import ChatMessage from "../QuestionContainer";
import * as Constant from "../../constants";

const GeneralInsuranceTypes = ({ value="", required = true, isValid, onValueChange,  formData}) => {
    const [error, setError] = useState("");
    const GeneralInsuranceTypes = Constant.GENERAL_INSURANCE_TYPES;
    
    const validate = (inputValue) => {
        if (!inputValue && required) {
            return "Please select insurance type";
        }
        return "";
    }

    const onChange = (e) => {
        const value = e.target.value;
        onValueChange(value);

        const errorMsg = validate(value);
        isValid(!!!errorMsg);

        if (errorMsg) {
            setError(errorMsg);
            return;
        }
        setError("")

    }

    useEffect(() => {
        const errorMsg = validate(value);
        setError(errorMsg);
        isValid(!!!errorMsg);
    }, []);

    const classNames = error ? "border-red-500" : "border-gray-300";

    return (
        <div className="w-[420px] flex flex-col items-center justify-center">
            <ChatMessage message=" Which type of health insurance you want?" isRequired={required} />
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 mt-5">
                {GeneralInsuranceTypes.map(_type => {
                    return (
                        <div className="card cursor-pointer" key={_type}>
                            <div className="relative bg-white opacity-70">
                                <div className="bg-gradient-to-b p-4 rounded-lg shadow-md flex items-center align-middle justify-between gap-3">
                                    <div className="flex items-center align-middle gap-3">
                                        <input
                                            type="radio"
                                            id="policy_exist"
                                            value={_type}
                                            name="policy_exist"
                                            checked={value === _type}
                                            className={`${classNames} border bg-gray-50 text-gray-900 text-sm rounded-lg block p-3`}
                                            onChange={onChange}
                                            autoFocus={false}
                                        />
                                        <p className="">{_type}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            {error && <p className="text-sm text-red-500 mt-2">{error}</p>}
        </div>
    )
}
export default GeneralInsuranceTypes;