import React, { useContext, useState } from "react";
import toast from "react-hot-toast";
import * as insuranceLeadService from "../Services/insuranceLeads";
import * as Components from "../Components";
import * as Context from "../Context";
import * as Constants from "../constants";
import * as FormConfig from "../FormConfig";

const ServicePolicy = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const formFields = FormConfig.RENEW_FORM_CONFIG;
  const { user } = useContext(Context.UserContext);

  const submitForm = (formData) => {
    formData['service_type'] = Constants.MAIN_SERVICES_ENUM.POLICY_RENEW;
    formData['created_by'] = user?.email;
    formData['name'] = user?.displayName;
    formData['email'] = user?.email;
    insuranceLeadService.addNewLead(formData).then((data) => {
      !data.error
        ? setFormSubmitted(true)
        : toast.error('Something went to wrong!')
    });
  }

  return (
    <div className="container mx-auto px-2">
      <div className="relative before:absolute before:top-0 before:left-1/2">
        <div className="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 pt-10 pb-10">
          <Components.HeadingHero
            part1Title="Policy"
            part2Title="Service"
            description="Policy renewal: Your key to ongoing protection and peace of mind. It ensures your coverage remains relevant, tailored, and confidently aligned with your evolving needs."
            colorReverse={true}
          />
          <div className="flex flex-col items-center container mx-auto px-2 mt-5">
            {
              user ? (
                !formSubmitted
                  ? <Components.DynamicForm formFields={formFields} onSubmit={submitForm} />
                  : <Components.FormFields.Success />
              )
                : <Components.LoginPanel />
            }
            <Components.BackToHome textOnly={true} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicePolicy;
