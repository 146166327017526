import Cookies from 'js-cookie';
import * as Constants from "../constants";

export const getObjectFromArray = (array, key, value) => array.find(obj => obj[key] === value);

export const getNextStep = (currentFieldIndex, formFields, formData) => {
    const nextFieldIndex = currentFieldIndex + 1;
    let nextStep = formFields[nextFieldIndex];

    if (currentFieldIndex < formFields.length - 1) {
        const dependsOn = nextStep.dependsOn;
        if (dependsOn) {
            const matched = dependsOn.filter(dependency => {
                const required_value = formData[dependency.field]
                return required_value === dependency?.value
            })
            if (matched.length !== dependsOn.length) {
                return getNextStep(nextFieldIndex, formFields, formData)
            }
        }
    }
    return nextStep;
}

// Set a cookie with the provided key, value, and expiration time
export const setCookie = (key, value, expiresInDays, isJson = false) => {
    if (isJson) {
        value = JSON.stringify(value)
    }
    
    Cookies.set(key, value, { expires: expiresInDays });
};

// Get the value of the cookie with the provided key
export const getCookie = (key, isJson) => {
    let value = Cookies.get(key);
    if (isJson && value) {
        return JSON.parse(value)
    }
    return value
};

// Remove the cookie with the provided key
export const removeCookie = (key) => {
    Cookies.remove(key);
};

export const getUser = () => {
    return getCookie('user', true);
}

export const removeUser = () => {
    removeCookie('user');
}

export const setUser = (user) => {
    setCookie('user', user, 1, true);
}

export const getLocalDateTime = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleString('en-IN', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    });
}

export const getInsuranceTypeDetailsByKey = (key) => {
    const details = Constants.INSURANCE_DETAILS.filter(insurance => insurance.key === key)
    if (details) {
        return details[0]
    }
    return {}
}

export const getServiceTypeDetailsByKey = (key) => {
    const details = Constants.MAIN_SERVICES.filter(service => service.key === key)
    if (details) {
        return details[0]
    }
    return {}
}