const OTHER_SERVICES_FORM_CONFIG = [
    {
        field: "mobile_no",
        dependsOn: [],
        isRequired: true,
        isLast: false,
        order: 3,
    },
    {
        field: "other_service",
        dependsOn: [],
        isRequired: true,
        isLast: false,
        order: 5,
    },
    {
        field: "text_input",
        dependsOn: [],
        isRequired: false,
        isLast: true,
        order: 6,
    }
]

export default OTHER_SERVICES_FORM_CONFIG;