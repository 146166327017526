import React from "react";
import { GoSearch } from "react-icons/go";

const SearchBox = ({ onChange, placeholder, width, className=null }) => {
    return (
        <div className={`w-[${width} ${className}`}>
            <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <GoSearch className='text-gray-500' />
                </div>
                <input
                    type="search"
                    onChange={(e) => onChange(e.target.value)}
                    className="block w-full p-3 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50"
                    placeholder={placeholder}
                />
            </div>
        </div>
    )
}
export default SearchBox;