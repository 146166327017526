import React, { useState } from "react";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import * as Utils from "../Utils";
import * as Constants from "../constants";
import FORM_FIELDS from "../formFields";

const DynamicForm = ({ onSubmit, formFields = [] }) => {
    const [formData, setFormData] = useState({});
    const [isValid, setIsValid] = useState(false);
    const [previousSteps, setPreviousSteps] = useState([]);
    const [currentField, setCurrentField] = useState(formFields[0]);

    const DynamicField = FORM_FIELDS[currentField?.field].component

    const goToNextStep = () => {
        if (isValid) {
            const currentFieldIndex = formFields.indexOf(currentField);
            let nextStep = Utils.getNextStep(currentFieldIndex, formFields, formData)
            let _previousSteps = [...previousSteps]
            _previousSteps.push(currentField)
            setPreviousSteps(_previousSteps);
            setCurrentField(nextStep);
        }
    }

    const goToPreviousStep = () => {
        const currentFieldIndex = formFields.indexOf(currentField);
        const previousStepIndex = currentFieldIndex - 1;

        if (previousStepIndex >= 0) {
            let _previousSteps = [...previousSteps]
            let previousStep = _previousSteps.pop()
            setCurrentField(previousStep);
            setPreviousSteps(_previousSteps);
        }
    }

    const submitForm = (formData) => {
        let _previousSteps = [...previousSteps];
        _previousSteps.push(currentField);

        // Remove invalid form fields i.e not a part of form
        Object.keys(formData).map((field) => {
            if (!Constants.FIELDS_TO_IGNORE_FROM_CLEANUP.includes(field)) {
                const isValidField = _previousSteps.find(_step => _step['field'] === field)
                if (!!!isValidField) {
                    delete formData[field];
                }
            }
        })
        onSubmit(formData);
    }

    const onFieldValueChange = (value) => {
        setFormData({ ...formData, [currentField.field]: value })
    }

    return (
        <div className="flex flex-col items-center justify-center gap-4 p-4">
            <div className="flex w-full items-center justify-center mb-2">
                {
                    DynamicField && currentField &&
                    <DynamicField
                        value={formData[currentField?.field]}
                        required={currentField.isRequired}
                        isValid={(_isValid) => setIsValid(_isValid)}
                        onValueChange={onFieldValueChange}
                        formData={formData}
                    />
                }
            </div>
            <div className="w-full flex items-center justify-center gap-6 md:gap-20">
                {
                    currentField.order > 1 &&
                    <button
                        onClick={goToPreviousStep}
                        type="button"
                        className="bg-slate-500 p-3 px-6 text-white rounded-md flex align-middle items-center justify-between"
                    >
                        <BsArrowLeftShort size={22} />
                        <p>Previous</p>
                    </button>
                }
                {
                    !currentField.isLast &&
                    (
                        <button
                            type="button"
                            onClick={goToNextStep}
                            disabled={!isValid}
                            className="bg-purple-700 disabled:bg-purple-400 p-3 px-8 text-white rounded-md flex align-middle items-center justify-between "
                        >
                            <p>Next</p>
                            <BsArrowRightShort size={22} />
                        </button>
                    )
                }
                {
                    currentField.isLast &&
                    (
                        <button
                            type="button"
                            onClick={()=>submitForm(formData)}
                            disabled={!isValid}
                            className="bg-purple-700 disabled:bg-purple-500 p-3 px-8 text-white rounded-md flex align-middle items-center justify-between "
                        >
                            <p>Submit</p>
                        </button>
                    )
                }
            </div>
        </div>
    )
}

export default DynamicForm;