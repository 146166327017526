import { useState } from 'react'
import { Switch } from '@headlessui/react'

const Toggle = ({ onChange, value = false, label = null, className = "" }) => {
    const [enabled, setEnabled] = useState(value)

    const toggle = () => {
        const toggled = !enabled;
        setEnabled(toggled);
        onChange(toggled);
    }

    return (
        <div className={`${className}`}>
            <Switch.Group>
                <div className="flex items-center gap-2">
                    <Switch.Label className="text-gray-800/80 font-semibold">{label}</Switch.Label>
                    <Switch
                        checked={enabled}
                        onChange={toggle}
                        className={`${enabled ? 'bg-blue-600' : 'bg-gray-200'
                            } relative inline-flex h-6 w-11 items-center rounded-full`}
                    >
                        <span
                            className={`${enabled ? 'translate-x-6' : 'translate-x-1'
                                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                        />
                    </Switch>
                </div>
            </Switch.Group>
        </div>
    )
}
export default Toggle;