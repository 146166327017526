import React from 'react';
import { useLocation } from 'react-router-dom';
import { BsFillTelephoneOutboundFill } from "react-icons/bs"

const CallNowButton = () => {
  const location = useLocation();
  const shouldHide = location.pathname.startsWith('/admin')
  
  if(shouldHide) return;
  
  return (
    <a
      href="tel:+919623299399"
      className="fixed bottom-6 right-4 bg-black rounded-md text-white p-2 px-4 text-sm shadow-md hover:bg-gray-800 z-50"
    >
      <button className="flex align-middle items-center">
        <BsFillTelephoneOutboundFill className="mr-3" /> Call Now
      </button>
    </a>
  );
};

export default CallNowButton;
