import FORM_FIELDS from "../formFields";
import * as Utils from "../Utils";
import DownloadFileButton from "./DownloadFileButton";

const LeadDetails = ({ leadDetails }) => {
    const excludeFields = ['id', 'created_at', 'created_by', 'service_type', 'assigned_at'];

    const renderValue = (key, value) => {
        switch (key) {
            case "insurance_type":
                return Utils.getInsuranceTypeDetailsByKey(value).title

            case "document_file":
            case "policy_file":
                return <DownloadFileButton filePath={value}/>

            case "relation":
                return <span class="bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded">
                    {value}
                </span>

            default:
                return value
        }
    }
    
    return (
        <div className="flex flex-col gap-2">
            {Object.entries(leadDetails).map(([key, value]) => (
                !excludeFields.includes(key) && (
                    <div key={key} className="rounded-md">
                        <div className="text-black font-bold">
                            {FORM_FIELDS[key]?.title}
                        </div>
                        <div style={{ whiteSpace: "pre-line" }}>
                            {typeof value === "string" ? renderValue(key, value) : (
                                <div>
                                    {value.map((element, index) => (
                                        <>
                                            <div key={`${key}-${index}`} className="pl-4 py-2">
                                                {Object.entries(element).map(([nestedField, nestedValue]) => (
                                                    <div key={`${key}-${index}-${nestedField}`} className="rounded-md">
                                                        <div className="text-black text-sm font-bold">{FORM_FIELDS[nestedField]?.title}</div>
                                                        <div style={{ whiteSpace: "pre-line" }}>
                                                            {nestedValue.toString() ? renderValue(nestedField, nestedValue) : "N/A"}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="py-2"><hr /></div>
                                        </>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                )
            ))}
        </div>
    );
};

export default LeadDetails;
