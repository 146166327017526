import React, { useEffect, useState } from "react";
import ChatMessage from "../QuestionContainer";

const NameField = ({ value="", required = true, isValid, onValueChange,  formData}) => {
    const [error, setError] = useState("");

    const validate = (inputValue) => {
        inputValue = inputValue?.trim()
        const regex = /^[0-9\b]+$/;
        if (!inputValue && required) {
            return "Mobile number is required!"
        }
        else if (inputValue && !regex.test(inputValue)) {
            return "Only digits are allowed!";
        }
        else if (inputValue && inputValue.length !== 10) {
            return "Mobile number must be 10 digits!";
        }
        return "";
    }

    const onChange = (e) => {
        const value = e.target.value;
        onValueChange(value);

        const errorMsg = validate(value);
        isValid(!!!errorMsg);

        if (errorMsg) {
            setError(errorMsg);
            return;
        }
        setError("")
    }

    useEffect(() => {
        const errorMsg = validate(value);
        setError(errorMsg);
        isValid(!!!errorMsg);
    }, []);

    const classNames = error ? "border-red-500" : "border-gray-300";

    return (
        <div className="w-[280px] md:w-[350px] flex flex-col items-center justify-center">
            <ChatMessage message="Enter your mobile number?" isRequired={required} />
            <input
                type="number"
                id="mobile_number"
                value={value}
                className={`${classNames} border bg-gray-50 text-gray-900 text-sm rounded-lg block w-full p-2.5 mt-3`}
                placeholder="e.g 9988776655"
                onChange={onChange}
                autoFocus={false}
            />
            {error && <p className="text-sm text-red-500 mt-2">{error}</p>}
        </div>
    )
}
export default NameField;