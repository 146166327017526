import React, { useEffect, useState } from 'react';
import { AiOutlineFileDone } from "react-icons/ai";
import { db } from '../../Libs/firebase';
import * as firestore from "firebase/firestore";
import * as Constants from "../../constants";
import * as Components from "../../Components";
import * as utils from "../../Utils";

const Home = () => {
  const [leads, setLeads] = useState([]);
  const [selectedLead, setSelectedLead] = useState('');
  const [myLeads, setMyLeads] = useState(false);
  const [selectedService, setSelectedService] = useState(Constants.MAIN_SERVICES[0].key);
  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setSelectedLead(null);
    getRealtimeLeads(selectedService, searchText, myLeads).finally(() => setIsLoading(false));
  }, [selectedService, searchText, myLeads]);

  const getRealtimeLeads = async (service_type = '', mobile_no, my_leads = false) => {
    const collectionRef = firestore.collection(db, Constants.FC_INSURANCE_LEADS);
    let q = firestore.query(
      collectionRef,
      firestore.where('service_type', '==', service_type),
      firestore.orderBy('created_at', 'desc')
    );

    if (mobile_no) {
      q = firestore.query(q, firestore.where('mobile_no', '==', mobile_no));
    }

    if (my_leads) {
      const current_user = utils.getUser();
      q = firestore.query(q, firestore.where('assigned_to', '==', current_user?.email));
    }

    return firestore.onSnapshot(q, (querySnapshot) => {
        const documents = querySnapshot.docs.map((doc) => {
          let _data = doc.data();
          _data['id'] = doc.id;
          return _data;
        });
        setLeads(documents);
    });
  };

  const handleLeadSelect = (lead) => {
    setSelectedLead(lead);
  };

  const renderLeadDetails = () => {
    if (selectedLead) {
      return (
        <div className='overflow-y-scroll h-[90%] p-4 pb-20 lg:pb-10'>
          <Components.LeadDetails leadDetails={selectedLead} />
        </div>
      );
    } else {
      return (
        <div className='flex justify-center items-center h-[50vh] gap-2'>
          <p className='text-gray-600 text-center'>
            Please select a lead to see details!
          </p>
        </div>
      );
    }
  };
  
  const renderLeads = () => {
    if (leads.length > 0) {
      return (
        <div className='flex flex-col gap-3 pr-2'>
          {leads.map((lead, idx) => (
            <Components.LeadSummaryRow
              key={idx}
              lead={lead}
              isSelected={lead.id === selectedLead?.id}
              onSelect={(_lead) => handleLeadSelect(_lead)}
            />
          ))}
        </div>
      );
    } else {
      return (
        <div className='mt-28'>
          <div className='flex justify-center items-center h-32 gap-2'>
            <AiOutlineFileDone size={40} className='text-gray-600' />
            <p className='text-gray-600 text-xl'>
              {isLoading ? "Please wait! Loading.." : "No enquiries yet!"}
            </p>
          </div>
        </div>
      );
    }
  };

  return (
    <div className='bg-white h-[76vh]'>
      <div className='flex justify-between pb-6'>
        <div className='flex gap-4'>
          <Components.SearchBox
            placeholder='Search by mobile number'
            onChange={(value) => setSearchText(value)}
            className="w-full md:w-70"
          />
          <Components.Toggle
            onChange={(enabled) => setMyLeads(enabled)}
            label="My Leads"
            value={myLeads}
            className="flex items-center w-60"
          />
        </div>
        <Components.MainServiceFilter
          onClick={setSelectedService}
          selectedService={selectedService}
        />
      </div>
      <div className='flex h-[100%]'>
        <div className='w-full md:w-2/3 overflow-y-scroll h-[100%] pb-20 lg:pb-10'>
          {renderLeads()}
        </div>
        <div className='w-full md:w-1/3 hidden md:block border rounded-r-md ml-1'>
          <div className="text-xl font-semibold text-gray-700 p-4 shadow-sm">
            Lead Details
          </div>
          {renderLeadDetails()}
        </div>
      </div>
      <div className='block md:hidden overflow-y-scroll'>
        {selectedLead && (
          <Components.SlideOver title="Lead Details" onClose={() => setSelectedLead(null)}>
            <Components.LeadDetails leadDetails={selectedLead} />
          </Components.SlideOver>
        )}
      </div>
    </div>
  );
};

export default Home;
