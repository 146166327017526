import React from "react";
import { MdClose } from "react-icons/md";

const SlideOver = ({ title, children, onClose }) => {
    return (
        <div class="relative z-50" role="dialog" aria-modal="true">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

            <div class="fixed inset-0 overflow-hidden">
                <div class="absolute inset-0 overflow-hidden">
                    <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                        <div class="pointer-events-auto relative w-screen max-w-md">
                            <div class="absolute right-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                                <button type="button" class="rounded-md text-gray-500" onClick={onClose}>
                                    <MdClose size={30} />
                                </button>
                            </div>

                            <div class="flex h-full flex-col overflow-y-scroll bg-white pt-6 shadow-xl">
                                <div class="px-4 sm:px-6">
                                    <div class="text-xl font-semibold leading-6 text-gray-700" id="slide-over-title">
                                        {title}
                                    </div>
                                </div>
                                <div class=" mt-6 flex-1 px-4 sm:px-6 overflow-y-scroll">
                                    {children}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SlideOver;