import React, { useEffect, useState } from "react";
import ChatMessage from "../QuestionContainer";

const DOB = ({ value="", required = true, isValid, onValueChange,  formData}) => {
    const [error, setError] = useState("");

    const validate = (inputValue) => {
        inputValue = inputValue?.trim()
        if (!inputValue && required) {
            return "Date of birth is required!";
        }
        return "";
    }

    const onChange = (e) => {
        const value = e.target.value;
        onValueChange(value);

        const errorMsg = validate(value);
        isValid(!!!errorMsg);

        if (errorMsg) {
            setError(errorMsg);
            return;
        }
        setError("")

    }

    useEffect(() => {
        const errorMsg = validate(value);
        setError(errorMsg);
        isValid(!!!errorMsg);
    }, []);

    const classNames = error ? "border-red-500" : "border-gray-300";

    return (
        <div className="w-[280px] md:w-[350px] flex flex-col items-center justify-center">
            <ChatMessage message="Enter your date of birth?" isRequired={required} />
            <input
                type="date"
                id="dbo_id"
                value={value}
                className={`${classNames} border bg-gray-50 text-gray-900 text-sm rounded-lg block w-full p-2.5 mt-3`}
                placeholder="Select birth date"
                onChange={onChange}
                autoFocus={false}
            />
            {error && <p className="text-sm text-red-500 mt-2">{error}</p>}
        </div>
    )
}
export default DOB;