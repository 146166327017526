import React, { useContext, useEffect, useRef, useState } from "react";
import UserProfileBadge from "./UserProfileBadge";
import logo from "../Assets/Images/logo.webp"
import GoogleLoginButton from "./GoogleLoginButton";
import * as Context from "../Context";

const AdminHeader = () => {
  const [showProfile, setShowProfile] = useState(false);
  const { user } = useContext(Context.UserContext);
  const profileRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        setShowProfile(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [profileRef]);


  return (
    <nav className="bg-primary border-gray-200 px-2 py-2 md:px-8 md:py-4 fixed  top-0 left-0 w-full bg-white/90 z-50 shadow-sm">
      <div className="container flex flex-wrap justify-between items-center mx-auto">
        <a href="/" className="flex items-center">
          <img src={logo} width="180" alt="logo" />
        </a>

        <div className="flex gap-4">
          {/* Large screen */}
          <div className="hidden md:flex md:gap-4">
            {
              user &&
              <UserProfileBadge
                name={user.displayName}
                email={user.email}
                photoURL={user.photoURL}
                role={user.role}
              />
            }
            <div className="text-sm">
              <GoogleLoginButton hidePhoto={true} />
            </div>
          </div>

          {/* Small screen */}
          <div className="block md:hidden pr-4">
            {
              user &&
              <div onClick={() => setShowProfile(!showProfile)}>
                <UserProfileBadge photoURL={user?.photoURL} photSize={45}/>
              </div>
            }
            {
              user && showProfile &&
              <div
                class="fixed md:hidden right-10 mt-2 z-50 inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-md shadow-md"
                ref={profileRef}
              >
                <div class="p-3">
                  <UserProfileBadge name={user.displayName} email={user.email} role={user.role}/>
                </div>
                <hr />
                <div className="flex py-3 justify-center">
                  <GoogleLoginButton hidePhoto={true} />
                </div>
              </div>
            }
          </div>
        </div>

      </div>
    </nav>
  );
};

export default AdminHeader;
