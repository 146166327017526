import React from "react";
import { useLocation } from 'react-router-dom';

const Footer = () => {
  const location = useLocation();
  const shouldHide = location.pathname.startsWith('/admin')
  
  if(shouldHide) return;
  
  return (
    <footer className="p-4 py-12 bg-slate-100 rounded-lg shadow flex items-center justify-center flex-col gap-y-3">
      <span className="text-gray-500 sm:text-center text-center gap-4">
        <div>
          <span>© 2022{" "}</span>
          <a href="https://flowbite.com" className="hover:underline">
           Insure Baba Pvt Ltd &nbsp;
          </a>
        </div>
        <p>All Rights Reserved.</p>
      </span>
    </footer>
  );
};

export default Footer;
