import React from "react";
import logo from "../Assets/Images/logo.webp"
import GoogleLoginButton from "./GoogleLoginButton";


const Header = () => {
  return (
    <nav className="bg-primary border-gray-200 px-4 sm:px-8 py-4 fixed  top-0 left-0 w-full bg-white/90 z-50 shadow-sm">
      <div className="container flex flex-wrap justify-between items-center mx-auto">
        <a href="/" className="flex items-center">
          <img src={logo} width="180" alt="logo" />
        </a>
        <div className="text-sm">
          <GoogleLoginButton />
        </div>
      </div>
    </nav>
  );
};

export default Header;
