import React, { useEffect, useState } from "react";
import ChatMessage from "../QuestionContainer";
import * as Constants from "../../constants";

const RiskCover = ({ value = "", required = true, isValid, onValueChange, formData }) => {
    const [error, setError] = useState("");

    const risk_cover_options = Constants.RISK_COVER_OPTIONS;

    const validate = (inputValue) => {
        inputValue = inputValue?.trim()

        if (!inputValue && required) {
            return "Risk cover is required!";
        }
        return "";
    }

    const onChange = (e) => {
        const value = e.target.value;
        onValueChange(value);

        const errorMsg = validate(value);
        isValid(!!!errorMsg);

        if (errorMsg) {
            setError(errorMsg);
            return;
        }
        setError("")

    }

    useEffect(() => {
        const errorMsg = validate(value);
        setError(errorMsg);
        isValid(!!!errorMsg);
    }, []);

    const classNames = error ? "border-red-500" : "border-gray-300";

    return (
        <div className="w-[280px] md:w-[350px] flex flex-col items-center justify-center">
            <ChatMessage message="How much cover you want?" isRequired={required} />
            <select
                onChange={onChange}
                value={value}
                className={`${classNames} block text-gray-900 text-sm appearance-none w-full p-2.5 mt-3 rounded-md border focus:outline-none focus:ring-indigo-500 focus:border-indigo-500`}
            >
                <option value="" disabled selected>Select risk cover</option>
                {risk_cover_options.map(_option => (
                    <option key={_option} value={_option}>{_option}</option>
                ))}
            </select>
            {error && <p className="text-sm text-red-500 mt-2">{error}</p>}
        </div>
    )
}
export default RiskCover;