import React from "react";
import { BsCheck2All } from "react-icons/bs";
import * as Avatar from "../../Assets/Avatars";

const Success = () => {
    return (
        <div className="w-[350px] flex flex-col items-center justify-center">
            {/* <BsCheck2All size={120} color="#6c2bd9" /> */}
            <img className="w-40 mb-4" src={Avatar.done} alt="Success"/>
            <p className="text-center font-semibold text-xl">
                We've received your details! We'll get back to you as soon as possible!
            </p>
        </div>
    )
}
export default Success;