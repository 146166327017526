import React, { useEffect, useState } from "react";
import { BsCheckAll, BsPlusCircle } from "react-icons/bs";
import ChatMessage from "../QuestionContainer";
import FileUpload from "../FileUpload";
import { deleteObject, ref } from "firebase/storage";
import { storage } from "../../Libs/firebase";

const RelatedDocuments = ({ value = [], required = true, isValid, onValueChange, formData }) => {
    value = !value ? value : [{ document_file: '' }];

    const fileUploadPath = 'documents';
    const [error, setError] = useState("");
    const [documentDetails, setDocumentDetails] = useState(value);

    const validate = (inputValue) => {
        let _message = "";
        inputValue?.forEach(document => {
            const doc_file = document.document_file;
            if (!doc_file && required) {
                _message = "Please upload all documents!";
            }
        })
        return _message;
    }

    const onChange = (index, field, value) => {
        const updatedFields = [...documentDetails];
        updatedFields[index][field] = value;
        setDocumentDetails(updatedFields);
    };

    const handleAddField = () => {
        setDocumentDetails([...documentDetails, { document_file: '' }]);
    };

    const handleRemoveField = (index) => {
        const updatedFields = [...documentDetails];
        const document_file = updatedFields[index]['document_file'];

        deleteFileFromFirebase(document_file).then(() => {
            updatedFields.splice(index, 1);
            setDocumentDetails(updatedFields);
        })

    };

    const handleRemoveFile = (index) => {
        const updatedFields = [...documentDetails];
        const document_file = updatedFields[index]['document_file'];
        if (!document_file) return;

        deleteFileFromFirebase(document_file).then(() => {
            updatedFields[index]['document_file'] = '';
            setDocumentDetails(updatedFields);
        })
    }

    const deleteFileFromFirebase = async (filePath) => {
        if (!filePath) return;
        const fileRef = ref(storage, filePath);
        try {
            await deleteObject(fileRef);
        } catch (error) {
            console.log('Error deleting file...!');
        }
    }

    const recoverRealFileName = (file_path) => {
        const file_parts = file_path.split('/');
        const firebase_file_name = file_parts[1];
        const file_name_parts = firebase_file_name.split('.');
        const file_format = file_name_parts.pop();
        const file_id = file_name_parts.pop();
        const real_file_name = file_name_parts.join(".")
        if (real_file_name) {
            return `${real_file_name}.${file_format}`;
        }
        
        return `${file_id}.${file_format}`;
    }

    useEffect(() => {
        const errorMsg = validate(documentDetails);
        setError(errorMsg);
        errorMsg ? isValid(false) : isValid(true);
        onValueChange(documentDetails);
    }, [documentDetails])

    return (
        <div className="w-[350px] flex flex-col items-center justify-center">
            <ChatMessage message="Upload documents related to selected services" isRequired={required} avatar="" />
            <div className="w-full mt-4 mb-2">
                <p className="text-xs text-red-400">
                    Please make sure you upload clear and readable documents
                </p>
            </div>
            <div className="w-full flex flex-col gap-3">
                {
                    documentDetails.map((document, idx) => {
                        return (
                            <div className="flex flex-col gap-2 md:gap-3 bg-gray-100 rounded-md p-4" key={idx}>
                                <div className="w-full">
                                    <div>
                                        {
                                            !document?.document_file
                                                ? <FileUpload
                                                    key={idx}
                                                    uploadPath={fileUploadPath}
                                                    prefix={""}
                                                    title="Upload document"
                                                    onUpload={(path) => onChange(idx, 'document_file', path)}
                                                />
                                                : <div>
                                                    <div>{recoverRealFileName(document.document_file)}</div>
                                                    <div className="flex gap-2 items-center justify-between">
                                                        <p className="flex items-center">
                                                            <BsCheckAll size={25} color="green" />
                                                            <span className="text-xs">File Uploaded!</span>
                                                        </p>
                                                        <div
                                                            onClick={() => handleRemoveFile(idx)}
                                                            className="bg-red-500 text-[10px] py-1 px-2 cursor-pointer text-white rounded-md flex align-middle items-center justify-between"
                                                        >
                                                            <p>Delete File</p>
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                    <div>
                                        {
                                            documentDetails.length > 1 &&
                                            <div className="flex items-center mt-2">
                                                <div
                                                    onClick={() => handleRemoveField(idx)}
                                                    className="gap-2 bg-slate-200 text-xs py-1 px-2 cursor-pointer text-gray-600 rounded-md flex align-middle items-center justify-between"
                                                >
                                                    <p>Remove document</p>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                <div
                    onClick={handleAddField}
                    className="p-2 cursor-pointer border-slate-200 rounded-md  border text-sm p-2rounded-md flex align-middle items-center gap-2"
                >
                    <BsPlusCircle />
                    <p className="text-gray-600" >Add another document</p>
                </div>
            </div>
            {error && <p className="text-sm text-red-500 mt-2">{error}</p>}
        </div>
    )
}
export default RelatedDocuments;