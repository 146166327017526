import React from "react";
import { Link } from "react-router-dom";
import * as Avatar from "../Assets/Avatars";
import * as Constants from "../constants";
import * as Components from "../Components";

const Home = () => {
  return (
      <div className="container mx-auto px-2">
        <div className="overflow-hidden before:absolute before:top-0 before:left-1/2">
          <div className="max-w-[85rem] mx-auto p-4 sm:px-6 lg:px-8 md:py-8">
            <section className="mt-4 md:mt-10">
              <div className="flex flex-col align-middle justify-center">
                <div className="flex align-middle justify-center">
                  <img className="w-40" src={Avatar.done} />
                </div>
                <div>
                  <div className="mt-5 max-w-4xl text-center mx-auto">
                    <h1 className="block font-bold text-gray-800 text-4xl md:text-5xl lg:text-6xl">
                      Let's Get
                      <span className="bg-clip-text bg-gradient-to-tl from-blue-600 to-violet-600 text-transparent"> Insured</span>
                    </h1>
                    <p className="text-xl mt-2 text-gray-800">We Serve What You Deserve!</p>
                  </div>
                  <div className="mt-5 max-w-4xl text-center mx-auto">
                    <p className="text-lg text-gray-600">
                      We are a top team in India providing Life Insurance Policy services and Business Risk Management solutions. We service 70000+ satisfied clients in India and overseas from approximately 22 locations in India.
                    </p>
                  </div>
                </div>
              </div>

              <div className="mt-5 md:mt-10">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {Object.values(Constants.MAIN_SERVICES).map(service => {
                    return (
                      <Link to={service.link_to} key={service.key}>
                        <div className="card cursor-pointer" key={service.key}>
                          <div className="relative bg-white opacity-70">
                            <div className="bg-gradient-to-b p-4 rounded-lg shadow-md flex items-center align-middle justify-between gap-3">
                              <div className="flex items-center align-middle gap-3">
                                <img
                                  width={service.image_size ? service.image_size : '14'}
                                  src={service.image}
                                  title={service.title}
                                />
                                <h3 className="text-lg font-bold">{service.title}</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              </div>
            </section>
            <Components.BecomeAnAgent />
            <section className="flex justify-center">
              <div className="max-w-md">
                <Components.InstallApp />
              </div>
            </section>
          </div>
        </div>
      </div>
  );
};

export default Home;
