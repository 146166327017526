import { db, storage } from "../Libs/firebase";
import { getDownloadURL, ref } from 'firebase/storage';
import * as firestore from "firebase/firestore";
import * as Constants from "../constants";

const collectionRef = firestore.collection(db, Constants.FC_INSURANCE_LEADS);

export const addNewLead = async (data) => {
    try {
        data['created_at'] = firestore.serverTimestamp();
        const _data = await firestore.addDoc(collectionRef, data);
        return { data: _data, error: null }
    } catch (error) {
        return { data: null, error: error }
    }
};

export const getFileDownloadURL = async (filePath) => {
    try {
        const fileRef = ref(storage, filePath);
        const url = await getDownloadURL(fileRef);
        return url;
    } catch (error) {
        console.error('Error downloading the file:', error);
    }
};

export const deleteLeadById = async (documentId) => {
    try {
        const documentRef = firestore.doc(db, Constants.FC_INSURANCE_LEADS, documentId);
        await firestore.deleteDoc(documentRef);
        return true
    } catch (error) {
        console.error("Error deleting document: ", error);
        return false
    }
};

export const updateLead = async (documentId, fields = null) => {
    try {
        if (!fields) return false;
        const documentRef = firestore.doc(db, Constants.FC_INSURANCE_LEADS, documentId);
        const resp = await firestore.updateDoc(documentRef, fields);
        return true
    } catch (error) {
        console.error("Error deleting document: ", error);
        return false
    }
}