import React, { useEffect, useState } from "react";
import ChatMessage from "../QuestionContainer";
import * as Constant from "../../constants";

const ServicePolicyOptions = ({ value="", required = true, isValid, onValueChange,  formData}) => {
    const [error, setError] = useState("");
    const policy_service_options = Constant.SERVICE_EXISTING_POLICY_OPTIONS;

    const validate = (inputValue) => {
        if (!inputValue && required) {
            return "Please select a service!";
        }
        return "";
    }

    const onChange = (e) => {
        const value = e.target.value;
        onValueChange(value);

        const errorMsg = validate(value);
        isValid(!!!errorMsg);

        if (errorMsg) {
            setError(errorMsg);
            return;
        }
        setError("")

    }

    useEffect(() => {
        const errorMsg = validate(value);
        setError(errorMsg);
        isValid(!!!errorMsg);
    }, []);

    const classNames = error ? "border-red-500" : "border-gray-300";

    return (
        <div className="w-[280px] md:w-[350px] flex flex-col items-center justify-center">
            <ChatMessage message="Which service you are looking for?" isRequired={required} />
            <div className="gap-3 mt-5 text-center">
                <div className="flex items-center align-middle w-full">
                    <select
                        onChange={onChange}
                        value={value}
                        className={`${classNames} block text-gray-900 text-sm appearance-none w-full px-4 py-2 pr-8 rounded-md border focus:outline-none focus:ring-indigo-500 focus:border-indigo-500`}
                    >
                        <option value="" disabled selected>Select a service</option>
                        {policy_service_options.map(_type => (
                            <option key={_type} value={_type}>{_type}</option>
                        ))}
                    </select>
                </div>
                {error && <p className="text-sm text-red-500 mt-2">{error}</p>}
            </div>
        </div>
    )
}
export default ServicePolicyOptions;