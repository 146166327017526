import React from "react";
import * as Constants from "../constants";

const MainServiceFilter = ({ onClick, selectedService }) => {
    return (
        <div>
            <div className="hidden lg:block">
                <div className="flex gap-6">
                    {
                        Constants.MAIN_SERVICES.map((service, idx) => {
                            const style = service.key === selectedService ? "border-gray-600 bg-black text-white" : "text-gray-800"
                            return (
                                <div
                                    key={idx}
                                    onClick={() => onClick(service.key)}
                                    className={`flex gap-2 text-sm font-medium p-2 px-4 rounded-md border-2 cursor-pointer ${style}`}
                                >
                                    <img src={service.image} width={20} className="bg-white rounded-full p-1" />
                                    {service.admin_title}
                                </div>
                            )
                        })
                    }
                </div>
            </div>

            <div className="block lg:hidden mx-auto w-full">
                <div className="fixed z-50 w-full h-14 max-w-lg -translate-x-1/2 bg-white border border-gray-200 rounded-3xl bottom-4 left-1/2 shadow-md">
                    <div className="grid h-full max-w-lg grid-cols-3 mx-auto">
                        {
                            Constants.MAIN_SERVICES.map((service, idx) => {
                                const style = service.key === selectedService ? "border-gray-600 bg-black text-white" : "text-gray-800"
                                return (
                                    <button
                                        key={idx}
                                        type="button"
                                        onClick={() => onClick(service.key)}
                                        className={`inline-flex flex-row gap-2 items-center justify-center rounded-3xl group ${style}`}
                                    >
                                        <img src={service.image} width={30} className="bg-white rounded-full p-1" />
                                        <span className="text-sm font-medium">
                                            {service.short_admin_title}
                                        </span>
                                    </button>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>

    )
}
export default MainServiceFilter;