import React, { useState } from 'react';
import { BiUserPlus, BiTrash } from "react-icons/bi";
import toast from "react-hot-toast";
import * as Components from "../Components";
import * as Utils from "../Utils";
import * as Constants from "../constants";

const LeadSummaryRow = ({ lead, onSelect, isSelected }) => {
    const [assignLead, setAssignLead] = useState(null);
    const [deleteLead, setDeleteLead] = useState(null);
    const currentUser = Utils.getUser();

    const selectedStyle = isSelected ? 'border-l-4 border-gray-800/50' : 'border'

    const getLabel = () => {
        return lead.insurance_type
            ? Utils.getInsuranceTypeDetailsByKey(lead.insurance_type)['title']
            : lead.service_policy_option
                ? lead.service_policy_option
                : 'Other';
    };

    const handleAssignLead = () => {
        setAssignLead(lead);
    };

    const handleDeleteLead = () => {
        setDeleteLead(lead);
    };

    return (
        <div>
            <div className={`flex flex-col bg-white p-3 shadow-sm rounded-md ${selectedStyle}`}>
                <div className='flex flex-col gap-2'>
                    <div className='flex justify-between cursor-pointer' onClick={() => onSelect(lead)}>
                        <span class="bg-blue-100 text-blue-800 text-xs px-2.5 py-0.5 rounded w-fit mb-1">
                            {getLabel()}
                        </span>
                    </div>
                    <div className='flex flex-col lg:flex-row gap-4 md:justify-between'>
                        <div className='flex flex-col gap-1 text-sm cursor-pointer' onClick={() => onSelect(lead)}>
                            <p>{lead.name} ({lead.mobile_no})</p>
                            <p class="text-gray-500 text-sm">
                                Submitted by {lead.email} on  {Utils.getLocalDateTime(lead.created_at.seconds)}
                            </p>
                        </div>
                        <div className='flex flex-row items-center gap-2 pr-3'>
                            <button
                                className="flex gap-1 px-3 py-1 text-xs bg-black text-white h-8 rounded-md align-middle items-center"
                                onClick={handleAssignLead}
                            >
                                <BiUserPlus size={20} />
                                <div>Assign</div>
                            </button>
                            {
                                currentUser.role === Constants.USER_GROUPS.ADMIN &&
                                <button
                                    className="flex gap-1 px-2 py-1 text-xs bg-red-600/75 text-white h-8 rounded-md align-middle items-center"
                                    onClick={handleDeleteLead}
                                >
                                    <BiTrash size={15} />
                                    <div>Delete</div>
                                </button>
                            }
                        </div>
                    </div>
                    <div className='flex w-full cursor-pointer' onClick={() => onSelect(lead)}>
                        {lead.assigned_to &&
                            <p class="text-gray-800 text-xs bg-yellow-100/50 px-2 py-1 rounded-sm w-fit">
                                Assigned to {lead.assigned_to}
                            </p>
                        }
                    </div>
                </div>
            </div>
            {
                deleteLead && <Components.DeleteLeadConfirm
                    lead={deleteLead}
                    onCancel={() => setDeleteLead(null)}
                    onSuccess={() => {
                        setDeleteLead(null);
                        toast.success("Lead deleted successfully!");
                        onSelect(null);
                    }}
                />
            }
            {
                assignLead && <Components.AssignLead
                    lead={assignLead}
                    onCancel={() => setAssignLead(null)}
                    onSuccess={(name) => { setAssignLead(null); toast.success(`Lead is assigned successfully to ${name}!`) }}
                />
            }
        </div>
    );
};

export default LeadSummaryRow;
