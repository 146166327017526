import React, { useState, useEffect } from "react";
let deferredPrompt;

const InstallApp = () => {
    const [installable, setInstallable] = useState(false);

    useEffect(() => {
        window.addEventListener("beforeinstallprompt", (e) => {
            e.preventDefault();
            deferredPrompt = e;
            setInstallable(true);
        });

        window.addEventListener('appinstalled', () => {
            console.log('INSTALL: Success');
        });
    }, []);

    const handleInstallClick = (e) => {
        setInstallable(false);
        deferredPrompt.prompt();
        deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
                console.log('User accepted the install prompt');
            } else {
                console.log('User dismissed the install prompt');
            }
        });
    };

    return (
        <>
            {
                installable &&
                <div className="flex flex-col items-center justify-center p-5 bg-white rounded-lg  w-full shadow mt-4">
                    <p className="mb-4 font-light text-gray-500 text-base">
                        Install app to get notifications on new updates.
                    </p>

                    <button
                        onClick={handleInstallClick}
                        className="bg-white w-32 install-button text-gray-600 py-2 px-3 border text-sm border-gray-300 rounded shadow-sm">
                        Install App
                    </button>
                </div>
            }
        </>
    )
}
export default InstallApp;