import React from "react";
import {MdOutlinePersonAdd} from "react-icons/md";

const BecomeAnAgent = () => {
    return (
        <section className="bg-white text-center mt-16">
            <div className="flex py-8 md:px-4 mx-auto sm:py-8 lg:px-6 justify-center">
                <div className="max-w-screen-md text-center">
                    <h2 className="mb-4 text-3xl tracking-tight font-extrabold text-gray-900">
                        Let's find out what brings us together.
                    </h2>
                    <p className="mb-8 font-light text-gray-500 sm:text-xl">
                        Discover a fulfilling career path in insurance. Help protect lives, build lasting relationships, and enjoy the freedom of a flexible schedule as an agent.
                        Join our agent network and unlock limitless possibilities.
                    </p>
                    <div className="flex justify-center space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
                        <a  href="tel:+919623299399" className="inline-flex items-center justify-center px-4 py-2.5 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 gap-3">
                            <MdOutlinePersonAdd size={25}/>
                            <p>Become an Agent</p>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default BecomeAnAgent;