import React, { useEffect, useState } from "react";
import ChatMessage from "../QuestionContainer";

const MemberDetails = ({ value = [], required = true, isValid, onValueChange, formData }) => {
    const [error, setError] = useState("");
    const [memberDetails, setMemberDetails] = useState(value)

    const membersCount = formData['members_count'];
    const loopArray = Array.from({ length: membersCount }, (_, index) => index + 1);

    const validate = (inputValue) => {
        let _message = null;
        inputValue?.forEach(member => {
            let _values = Object.values(member);
            _values = _values.filter(_value => !!_value)
            if (_values.length < 3) {
                _message = "All fields are required!"
            }
        })
        return _message;
    }

    const onChange = (e) => {
        const target = e.target;
        const [name, idx] = target.name.split("-");
        let value = target.value;

        if (name === 'have_disease') {
            value = target.checked === true ? 'Yes' : 'No'
        }

        setMemberDetails((prevMemberDetails) => {
            const updatedMemberDetails = [...prevMemberDetails];
            const updatedMember = { ...updatedMemberDetails[idx] };
            updatedMember[name] = value;
            updatedMemberDetails[idx] = updatedMember;

            const message = validate(updatedMemberDetails);
            setError(message)
            isValid(!!!message)
            onValueChange(updatedMemberDetails)
            return updatedMemberDetails;
        });

    };

    useEffect(() => {
        let errorMsg = "";
        const empty_response = {
            "relation": '',
            "dob": '',
            "have_disease": 'No',
        }

        if (value && value.length <= membersCount) {
            const extraFields = membersCount - value.length;
            const extraArray = Array.from({ length: extraFields }, () => empty_response);
            const updatedValue = [...value, ...extraArray];
            setMemberDetails(updatedValue);
            errorMsg = validate(updatedValue);
          } else {
            const _details = Array.from(loopArray, () => empty_response);
            setMemberDetails(_details);
            onValueChange(_details);
            errorMsg = validate(_details);
          }
          
          setError(errorMsg);
          isValid(!errorMsg);
    }, []);

    const classNames = error ? "border-red-500" : "border-gray-300";
    const memberRelations = ["Self", "Mother", "Father", "Wife", "Son", "Daughter"]

    return (
        <div className="w-72 md:w-[350px] flex flex-col items-center justify-center">
            <ChatMessage message="Please provide member details." isRequired={required} />
            <div className="w-full grid gap-3 mt-4">
                {
                    memberDetails?.map((details, idx) =>
                        <div className="w-full card bg-gray-100 rounded-md p-4" key={idx}>
                            <p className="mb-2">Member {idx + 1}</p>
                            <div>
                                <div className="text-sm">
                                    <div className="flex flex-col md:flex md:flex-row gap-2 md:gap-3">
                                        <div>
                                            <p className="pb-1 text-gray-600">Relation</p>
                                            <select
                                                value={details.relation}
                                                id={`relation-${idx}`}
                                                name={`relation-${idx}`}
                                                onChange={onChange}
                                                className={`${classNames} w-full text-gray-600 text-sm rounded-md border focus:outline-none focus:ring-indigo-500 focus:border-indigo-500`}
                                            >
                                                <option value="" disabled selected>Select Relation</option>
                                                {memberRelations.map(relation => (
                                                    <option key={relation} value={relation}>{relation}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div>
                                            <p className="pb-1 text-gray-600">Date of Birth</p>
                                            <input
                                                type="date"
                                                id={`dbo-{idx}`}
                                                name={`dob-${idx}`}
                                                value={details.dob}
                                                onChange={onChange}
                                                className={`${classNames} border bg-gray-50 text-gray-600 text-sm rounded-lg block w-full`}
                                                placeholder="Date of Birth"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-left my-3">
                                        <div className="flex items-center align-middle gap-x-3">
                                            <input
                                                type="checkbox"
                                                id={`have_disease-${idx}`}
                                                name={`have_disease-${idx}`}
                                                checked={details.have_disease === "Yes"}
                                                onChange={onChange}
                                                className={`${classNames} border bg-gray-50 text-sm rounded-lg block p-3`}
                                            />
                                            <label className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer" />
                                        </div>
                                        <p className="text-gray-600">Any existing disease?</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
            {error && <p className="text-sm text-red-500 mt-2">{error}</p>}
        </div>
    )
}
export default MemberDetails;