import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../Libs/firebase';

const DownloadFileButton = ({ filePath }) => {
    const downloadFile = async () => {
        try {
            const fileRef = ref(storage, filePath);
            const url = await getDownloadURL(fileRef);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('target', '_blank');
            link.setAttribute('download', 'your-file-name.ext');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    };

    return (
        <button onClick={downloadFile} className='bg-black text-white text-xs px-2 py-1 rounded-lg'>
            View/Download
        </button>
    )
};

export default DownloadFileButton;
